const PROMPT_DELAY = 3000;
const MAX_PROMPT_ATTEMPTS = 1;
let promptCounter = 0;

const modalBg = document.querySelector('.modal__bg');
const closeBtn = document.querySelector('.close__btn');
const linkEl = document.querySelector('.js-modal-annoy-link');

function showAnnoyModal(displayType) {
  modalBg.style.display = displayType;
}

function openModalAnnoy() {
  linkEl.addEventListener('click', stopShowingModal);
  if (promptCounter === MAX_PROMPT_ATTEMPTS) {
    return;
  }
  setTimeout(() => {
    promptCounter += 1;
    showAnnoyModal('block');
  }, PROMPT_DELAY);
}

function stopShowingModal() {
  linkEl.removeEventListener('click', stopShowingModal);
  promptCounter = MAX_PROMPT_ATTEMPTS;
  showAnnoyModal('none');
}

closeBtn.addEventListener('click', function () {
  showAnnoyModal('none');
  openModalAnnoy();
});

window.addEventListener('click', function (event) {
  if (event.target === modalBg) {
    showAnnoyModal('none');
  }
});

openModalAnnoy();
