// export const firebaseConfig = {
//     apiKey: "AIzaSyA13UMSUgNnQsoBYWF8MgD7zudCTmFwvtw",
//     authDomain: "cocktail-dcf05.firebaseapp.com",
//     databaseURL: "https://cocktail-dcf05-default-rtdb.europe-west1.firebasedatabase.app",
//     projectId: "cocktail-dcf05",
//     storageBucket: "cocktail-dcf05.appspot.com",
//     messagingSenderId: "1016519406609",
//     appId: "1:1016519406609:web:31c865f69462bafe38edb0"
// };
export const firebaseConfig = {
  apiKey: "AIzaSyB2GH8APkR_t_1kkGl-q1WSZywhFKjHT1E",
  authDomain: "cocktails-project-1a3df.firebaseapp.com",
  databaseURL: "https://cocktails-project-1a3df-default-rtdb.firebaseio.com",
  projectId: "cocktails-project-1a3df",
  storageBucket: "cocktails-project-1a3df.appspot.com",
  messagingSenderId: "970754555743",
  appId: "1:970754555743:web:7b4d7598445c07ab5740ea",
  // measurementId: "G-Z3VKZCE0JB"
}